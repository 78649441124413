import { css } from 'linaria';
import { cssTheme } from '@uds/theme-utils';
import { breakpoints } from '@ux/responsive/constants';

const ctaBlock = css`
padding: ${cssTheme.gu3};

@media (min-width: ${breakpoints.lg}px) {
  padding ${cssTheme.gu4};
}
`;

export const classNames = {
  ctaBlock,
};
