import { cssTheme } from '@uds/theme-utils';
import { css } from 'linaria';
import { breakpoints } from '@ux/responsive/constants';

const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const introBlockContainer = css`
  @media (min-width: ${breakpoints.md}px) {
    position: sticky;
    top: ${cssTheme.gu5};
  }
  @media (min-width: ${breakpoints.lg}px) {
    top: ${cssTheme.gu6};
  }
`;

export const classNames = {
  container,
  introBlockContainer,
};
