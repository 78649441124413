import React, { Suspense, lazy } from 'react';
import PageLoader from 'presentation/components/screens/PageLoader';

const EditPermissionsPage = lazy(
  () => import('presentation/pages/delegation/EditPermissionsPage/ConnectedEditPermissionsPage'),
);

const EditPermissionsPageWrapper = () => (
  <Suspense fallback={<PageLoader />}>
    <EditPermissionsPage />
  </Suspense>
);

export default EditPermissionsPageWrapper;
