import PageTemplate from 'presentation/components/elements/PageTemplate';
import Message from '@ux/message';
import { usePageTitle } from 'domain/selectors/common';

const pageId = 'uam.pageTitle.invoices';

const Invoices = () => {
  const title = usePageTitle(pageId);

  return (
    <PageTemplate
      pageId={pageId}
      pageIntroTitle={<Message id="uam.invoices.page.title" />}
      pageIntroText={<Message id="uam.invoices.page.text" />}
      title={title}
    />
  );
};

Invoices.displayName = 'Invoices';
export default Invoices;
