import { useExternalLinks } from 'domain/selectors/config';
import Card from 'presentation/components/elements/Card';
import Message from '@ux/message';
import Button from '@ux/button';
import { Column } from '@ux/grid';
import { ScreenConfigKeys } from 'core/config';

interface Props {
  readonly pageName: ScreenConfigKeys;
  readonly columnConfig: Record<string, number>;
  readonly wrapperClass?: string;
}

const ExternalLinks = ({ pageName, columnConfig, wrapperClass }: Props) => {
  const externalLinks = useExternalLinks(pageName);
  if (externalLinks == null) {
    return null;
  }
  return (
    <>
      {externalLinks.map((item) => (
        <Column key={item.lexiconKeyTitle} {...columnConfig}>
          <div className={wrapperClass}>
            <Card
              heading={<Message id={item.lexiconKeyTitle} />}
              subheading={
                <If condition={Boolean(item.lexiconKeySubtitle)}>
                  <Message id={item.lexiconKeySubtitle} />
                </If>
              }
              ctaBlockContent={
                <Button to={item.url ?? ''} {...(item.openInNewTab ? { target: '_blank' } : null)}>
                  <Message id={item.lexiconKeyButton} />
                </Button>
              }
            />
          </div>
        </Column>
      ))}
    </>
  );
};

ExternalLinks.displayName = 'ExternalLinks';
export default ExternalLinks;
