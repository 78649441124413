import Glyph from '@ux/glyphs';
import { useTheme } from '@uds/theme-utils';

import { useClassNames } from './SupportStrip.stylesheet';

interface Props {
  readonly phoneNumber: string;
}

const PhoneNumber = ({ phoneNumber }: Props) => {
  const { theme } = useTheme();
  const classNames = useClassNames();
  return (
    <div className={classNames.phoneContainer}>
      <Glyph alt="" icon="call" size={25} color={theme.palette.primary} />
      {phoneNumber}
    </div>
  );
};

PhoneNumber.displayName = 'PhoneNumber';
export default PhoneNumber;
