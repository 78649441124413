import { lazy } from 'react';
import HeaderBackLink from 'presentation/components/elements/HeaderBackLink';
import Message from '@ux/message';
import IntroBlock from 'presentation/components/elements/IntroBlock';
import FormFocus from 'presentation/components/elements/FormFocus';
import { routeConfig } from 'domain/constants/routes';
import { usePageTitle } from 'domain/selectors/common';

const ChangeAccountEmail = lazy(() => import('presentation/components/modules/ChangeAccountEmail'));

const pageId = 'uam.pageTitle.changeEmail';

const ChangeEmail = () => {
  const title = usePageTitle(pageId);
  return (
    <FormFocus
      pageId={pageId}
      title={title}
      introBlock={
        <IntroBlock
          heading={<Message id="uam.profile.email.edit.title" />}
          headingAs="h1"
          body={<Message id="uam.profile.email.edit.text" />}
          bodyAs="p"
        />
      }
      backLink={
        <HeaderBackLink to={routeConfig.profile}>
          <Message id="uam.profile.email.backLink" />
        </HeaderBackLink>
      }
    >
      <ChangeAccountEmail />
    </FormFocus>
  );
};

ChangeEmail.displayName = 'ChangeEmail';
export default ChangeEmail;
