import { Suspense, lazy } from 'react';
import PageLoader from 'presentation/components/screens/PageLoader';

const CreateInvitation = lazy(() => import('presentation/components/screens/Delegation/CreateInvitation'));

const CreateInvitationPage = () => (
  <Suspense fallback={<PageLoader />}>
    <CreateInvitation />
  </Suspense>
);

CreateInvitationPage.displayName = 'CreateInvitationPage';

export default CreateInvitationPage;
