import { ReactNode, useState, useEffect } from 'react';
import { Container } from '@ux/grid';
import Button, { Kind, GlyphAlignment } from '@ux/button';

import { classNames } from './HeaderBackLink.stylesheet';

interface Props {
  readonly to: string;
  readonly children: ReactNode;
}

const HeaderBackLink = ({ to, children }: Props) => {
  const [useContainer, setUseContainer] = useState({ matches: window.innerWidth >= 1020 });
  const ContainerComponent = useContainer.matches ? Container : 'div';

  useEffect(() => {
    // This needs to match with the in the bookends, and that changes on 1020px;
    const mediaQuery = window.matchMedia('(min-width: 1020px)');
    mediaQuery.addListener(setUseContainer);

    return () => mediaQuery.removeListener(setUseContainer);
  }, []);

  return (
    <div className={classNames.backLinkWrapper}>
      <ContainerComponent>
        <Button
          kind={Kind.HIGHLIGHT}
          block={false}
          className={classNames.button}
          to={to}
          glyph="arrowLeft"
          glyphAlignment={GlyphAlignment.START}
        >
          {children}
        </Button>
      </ContainerComponent>
    </div>
  );
};

HeaderBackLink.displayName = 'HeaderBackLink';
export default HeaderBackLink;
