import * as React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap-reboot/reboot.css';

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line import/no-extraneous-dependencies
  import('@axe-core/react').then((axe) => {
    axe.default(React, ReactDOM, 1000, {});
  });
}

const render = (Component: React.ComponentType<any>) => () => {
  ReactDOM.render(<Component />, document.getElementById('root'));
};

export default render;
