import { Suspense, lazy } from 'react';
import PageLoader from 'presentation/components/screens/PageLoader/index';

const Profile = lazy(() => import('presentation/components/screens/Profile'));

const ProfilePage = () => (
  <Suspense fallback={<PageLoader />}>
    <Profile />
  </Suspense>
);

ProfilePage.displayName = 'ProfilePage';

export default ProfilePage;
