import { lazy } from 'react';
import Message from '@ux/message';
import PageTemplate from 'presentation/components/elements/PageTemplate';
import { usePageTitle } from 'domain/selectors/common';

const ChangePasswordCard = lazy(() => import('presentation/components/modules/ChangePasswordCard'));

const pageId = 'uam.pageTitle.security';

const Security = () => {
  const title = usePageTitle(pageId);

  return (
    <PageTemplate
      pageId={pageId}
      title={title}
      pageIntroTitle={<Message id="uam.security.pageIntro.title" />}
      pageIntroText={<Message id="uam.security.pageIntro.text" />}
    >
      <ChangePasswordCard />
    </PageTemplate>
  );
};
Security.displayName = 'Security';

export default Security;
