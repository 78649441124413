import { ReactChild } from 'react';
import { PaddingBox } from '@ux/padding-box';
import { Heading02, SubHeading02, Typelockup } from '@ux/typography';

interface Props {
  readonly heading: ReactChild;
  readonly body: ReactChild;
  readonly headingAs?: string;
  readonly bodyAs?: string;
}

const IntroBlock = ({ heading, body, headingAs, bodyAs }: Props) => (
  <PaddingBox>
    <Typelockup
      heading={<Heading02 component={headingAs}>{heading}</Heading02>}
      subheading={
        <If condition={body != null}>
          <SubHeading02 component={bodyAs}>{body}</SubHeading02>
        </If>
      }
    />
  </PaddingBox>
);

IntroBlock.displayName = 'IntroBlock';
export default IntroBlock;
