import { useAppConfig } from '@ux/whitelabel';
import { useSupportedLanguages } from '@ux/language';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';
import { UAMConfig, ScreenConfigKeys } from 'core/config';

const getUrl = (key: string) => {
  switch (key) {
    case 'profile':
      return '';
    default:
      return key;
  }
};

function useUAMConfig() {
  return useAppConfig<UAMConfig>('uam');
}

export function useProfileConfig() {
  const config = useUAMConfig();

  return config.screens.profile;
}

export function makeRoutingConfig(config: UAMConfig) {
  const { menu } = config;

  return {
    routes: menu.map(({ name }) => name),
    links: menu.map(({ name, lexiconKey, url }, index) => ({
      key: index.toString(),
      label: lexiconKey ?? name,
      url: url ?? `/${getUrl(name)}`,
      exact: name === 'profile',
      name,
    })),
  };
}

export function useRoutingConfig() {
  return makeRoutingConfig(useUAMConfig());
}

export function makeUseExternalLinks(key: ScreenConfigKeys, uamConfig: UAMConfig) {
  return uamConfig.screens[key]?.externalLinks;
}

export function useExternalLinks(key: ScreenConfigKeys) {
  return makeUseExternalLinks(key, useUAMConfig());
}

export function makeGetLanguageMap(supportedLanguages: ReadonlyArray<string>, location: Location) {
  const { pathname } = location;
  return supportedLanguages.reduce((languageMap, language) => {
    return {
      ...languageMap,
      [language]: `${pathname}`,
    };
  }, {});
}

export function useGetLanguageMap() {
  return makeGetLanguageMap(useSupportedLanguages(), useLocation());
}
