import { ReactNode } from 'react';

import { classNames } from './CTABlock.stylesheet';

interface Props {
  readonly children: ReactNode;
}

const CTABlock = ({ children }: Props) => <div className={classNames.ctaBlock}>{children}</div>;

CTABlock.displayName = 'CTABlock';
export default CTABlock;
