export const routeConfig = {
  profile: '/',
  changeEmail: '/profile/update-email',
  editProfile: '/profile/edit',
  communication: '/communication',
  emailConfirm: '/email/confirm',
  security: '/security',
  changePassword: '/security/password',
  paymentMethods: '/paymentMethods',
  invoices: '/invoices',
  subscriptions: '/subscriptions',
  delegation: {
    root: '/delegation',
    createInvitation: '/delegation/invitation/create',
    invitationError: '/delegation/invitation/error',
    invitation: '/delegation/invitation/:id',
    grantor: '/delegation/grantor/:id',
    delegatePending: '/delegation/delegate/pending/:id',
    permissions: '/delegation/delegate/:id/permissions',
    delegate: '/delegation/delegate/:id',
  },
};
