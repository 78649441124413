import { useBrandConfig } from '@ux/whitelabel';
import { formatPhoneNumber } from 'crosscutting/utils';

import SupportStrip from './SupportStrip';

const ConnectedSupportStrip = () => {
  const config = useBrandConfig();
  return <SupportStrip phoneNumber={formatPhoneNumber(config.support.phone)} />;
};

ConnectedSupportStrip.displayName = 'ConnectedSupportStrip';
export default ConnectedSupportStrip;
