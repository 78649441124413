import { ReactChild, ReactNode } from 'react';
import Panel from '@ux/panel';
import { PaddingBox } from '@ux/padding-box';
import { Heading03, SubHeading02, Typelockup } from '@ux/typography';
import CTABlock from 'presentation/components/elements/CTABlock';

interface Props {
  readonly heading: ReactChild;
  readonly subheading?: ReactChild;
  readonly ctaBlockContent?: ReactNode;
  readonly children?: ReactNode;
}

const Card = ({ heading, subheading, ctaBlockContent, children }: Props) => {
  return (
    <Panel>
      <PaddingBox>
        <Typelockup
          heading={<Heading03 component="h2">{heading}</Heading03>}
          subheading={
            <If condition={subheading != null}>
              <SubHeading02 component="span">{subheading}</SubHeading02>
            </If>
          }
        />
      </PaddingBox>
      {children}
      <If condition={ctaBlockContent != null}>
        <CTABlock>{ctaBlockContent}</CTABlock>
      </If>
    </Panel>
  );
};

Card.displayName = 'Card';
export default Card;
