import { lazy } from 'react';
import HeaderBackLink from 'presentation/components/elements/HeaderBackLink';
import Message from '@ux/message';
import IntroBlock from 'presentation/components/elements/IntroBlock';
import FormFocus from 'presentation/components/elements/FormFocus';
import { usePageTitle } from 'domain/selectors/common';
import { routeConfig } from 'domain/constants/routes';

const ChangeAccountDetails = lazy(() => import('presentation/components/modules/ChangeAccountDetails'));

const pageId = 'uam.pageTitle.editProfile';

const EditProfile = () => {
  const title = usePageTitle(pageId);
  return (
    <FormFocus
      pageId={pageId}
      title={title}
      introBlock={
        <IntroBlock
          heading={<Message id="uam.profile.details.edit.title" />}
          headingAs="h1"
          body={<Message id="uam.profile.details.edit.subtitle" />}
        />
      }
      backLink={
        <HeaderBackLink to={routeConfig.profile}>
          <Message id="uam.profile.email.backLink" />
        </HeaderBackLink>
      }
    >
      <ChangeAccountDetails />
    </FormFocus>
  );
};

EditProfile.displayName = 'EditProfile';
export default EditProfile;
