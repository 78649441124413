// I suggest to keep one export pr screen for better code-splitting

export const multiOptionCard = {
  components: {
    multiOptionCard: {
      expanded: 'uam.components.multiOptionCard.expanded',
      expand: 'uam.components.multiOptionCard.expand',
    },
  },
};

export const indeterminateCheckbox = {
  components: {
    indeterminateCheckbox: {
      srText: 'uam.components.indeterminateCheckbox.srText',
    },
  },
};

export const changePasswordScreen = {
  security: {
    password: {
      backLink: 'uam.security.password.backLink',
    },
    changePassword: {
      title: 'uam.security.changePassword.title',
      text: 'uam.security.changePassword.text',
    },
  },
};

export const inviteDelegateScreen = {
  delegation: {
    inviteDelegate: {
      pageTitle: 'uam.delegation.inviteDelegate.pageTitle',
      backLink: 'uam.delegation.inviteDelegate.backLink',
      intro: {
        heading: 'uam.delegation.inviteDelegate.intro.heading',
        body: 'uam.delegation.inviteDelegate.intro.body',
      },
      form: {
        preheading: 'uam.delegation.inviteDelegate.form.preheading',
        firstName: {
          label: 'uam.delegation.inviteDelegate.form.firstName.label',
        },
        lastName: {
          label: 'uam.delegation.inviteDelegate.form.lastName.label',
        },
        email: {
          label: 'uam.delegation.inviteDelegate.form.email.label',
        },
        error: 'uam.delegation.inviteDelegate.form.error',
      },
      validation: {
        firstName: {
          required: 'uam.delegation.inviteDelegate.validation.firstName.required',
          invalid: 'uam.delegation.inviteDelegate.validation.firstName.invalid',
          min: 'uam.delegation.inviteDelegate.validation.firstName.min',
          max: 'uam.delegation.inviteDelegate.validation.firstName.max',
        },
        lastName: {
          required: 'uam.delegation.inviteDelegate.validation.lastName.required',
          invalid: 'uam.delegation.inviteDelegate.validation.lastName.invalid',
          min: 'uam.delegation.inviteDelegate.validation.lastName.min',
          max: 'uam.delegation.inviteDelegate.validation.lastName.max',
        },
        email: {
          required: 'uam.delegation.inviteDelegate.validation.email.required',
          invalid: 'uam.delegation.inviteDelegate.validation.email.invalid',
        },
      },
      send: 'uam.delegation.inviteDelegate.send',
      cancel: 'uam.delegation.inviteDelegate.cancel',
    },
  },
};

export const acceptInviteScreen = {
  delegation: {
    acceptInvite: {
      heading: 'uam.delegation.acceptInvite.heading',
      subHeading: 'uam.delegation.acceptInvite.subHeading',
      accept: 'uam.delegation.acceptInvite.accept',
      reject: 'uam.delegation.acceptInvite.reject',
      failed: 'uam.delegation.acceptInvite.failed',
      rejectConfirmation: {
        eyebrow: 'uam.delegation.acceptInvite.rejectConfirmation.eyebrow',
        heading: 'uam.delegation.acceptInvite.rejectConfirmation.heading',
        text: 'uam.delegation.acceptInvite.rejectConfirmation.text',
        confirm: 'uam.delegation.acceptInvite.rejectConfirmation.confirm',
        cancel: 'uam.delegation.acceptInvite.rejectConfirmation.cancel',
      },
    },
  },
};

export const deleteInvitation = {
  delegation: {
    deleteInvitation: {
      failed: 'uam.delegation.deleteInvitation.failed',
    },
  },
};

export const accessOverviewScreen = {
  delegation: {
    accessOverview: {
      title: {
        heading: 'uam.delegation.accessOverview.title.heading',
        subHeading: 'uam.delegation.accessOverview.title.subHeading',
        sendInvite: 'uam.delegation.accessOverview.title.sendInvite',
      },
      delegates: {
        heading: 'uam.delegation.accessOverview.delegates.heading',
        subHeading: 'uam.delegation.accessOverview.delegates.subHeading',
        error: 'uam.delegation.accessOverview.delegates.error',
      },
      grantors: {
        heading: 'uam.delegation.accessOverview.grantors.heading',
        subHeading: 'uam.delegation.accessOverview.grantors.subHeading',
        error: 'uam.delegation.accessOverview.grantors.error',
      },
    },
  },
};

export const delegateScreen = {
  delegation: {
    delegate: {
      pageTitle: 'uam.delegation.delegate.pageTitle',
      timeRemaining: 'uam.delegation.delegate.timeRemaining',
      status: {
        inviteSent: 'uam.delegation.delegate.status.inviteSent',
        inviteAccepted: 'uam.delegation.delegate.status.inviteAccepted',
        active: 'uam.delegation.delegate.status.active',
      },
      noPermissions: {
        heading: 'uam.delegation.delegate.noPermissions.heading',
        subHeading: 'uam.delegation.delegate.noPermissions.subheading',
      },
      error: 'uam.delegation.delegate.error',
      removeDelegate: {
        remove: 'uam.delegation.delegate.removeDelegate.remove',
        failed: 'uam.delegation.delegate.removeDelegate.failed',
        confirmation: {
          heading: 'uam.delegation.delegate.removeDelegate.confirmation.heading',
          text: 'uam.delegation.delegate.removeDelegate.confirmation.text',
          confirm: 'uam.delegation.delegate.removeDelegate.confirmation.confirm',
          cancel: 'uam.delegation.delegate.removeDelegate.confirmation.cancel',
        },
      },
    },
  },
};

export const pendingDelegateScreen = {
  delegation: {
    pendingDelegate: {
      pageTitle: 'uam.delegation.pendingDelegate.pageTitle',
      heading: 'uam.delegation.pendingDelegate.heading',
      subHeading: 'uam.delegation.pendingDelegate.subheading',
      error: 'uam.delegation.pendingDelegate.error',
      cancelInvite: {
        cancel: 'uam.delegation.pendingDelegate.cancelInvite.cancel',
        confirmation: {
          heading: 'uam.delegation.pendingDelegate.cancelInvite.confirmation.heading',
          text: 'uam.delegation.pendingDelegate.cancelInvite.confirmation.text',
          confirm: 'uam.delegation.pendingDelegate.cancelInvite.confirmation.confirm',
          cancel: 'uam.delegation.pendingDelegate.cancelInvite.confirmation.cancel',
        },
      },
    },
  },
};

export const grantorScreen = {
  delegation: {
    grantor: {
      pageTitle: 'uam.delegation.grantor.pageTitle',
      noPermissions: {
        heading: 'uam.delegation.grantor.noPermissions.heading',
        subHeading: 'uam.delegation.grantor.noPermissions.subheading',
      },
      error: 'uam.delegation.grantor.error',
      removeGrantor: {
        remove: 'uam.delegation.grantor.removeGrantor.remove',
        failed: 'uam.delegation.grantor.removeGrantor.failed',
        confirmation: {
          heading: 'uam.delegation.grantor.removeGrantor.confirmation.heading',
          text: 'uam.delegation.grantor.removeGrantor.confirmation.text',
          confirm: 'uam.delegation.grantor.removeGrantor.confirmation.confirm',
          cancel: 'uam.delegation.grantor.removeGrantor.confirmation.cancel',
        },
      },
    },
  },
};

export const relationship = {
  delegation: {
    relationship: {
      date: {
        start: 'uam.delegation.relationship.date.start',
        end: 'uam.delegation.relationship.date.end',
      },
    },
  },
};

export const pagePortal = {
  delegation: {
    pagePortal: {
      pagination: 'uam.delegation.pagePortal.pagination',
    },
  },
};

export const acceptInviteErrorScreen = {
  delegation: {
    acceptInviteError: {
      expired: {
        intro: {
          heading: 'uam.delegation.acceptInviteError.expired.intro.heading',
          body: 'uam.delegation.acceptInviteError.expired.intro.body',
        },
      },
    },
  },
};

export const delegationBackLink = {
  delegation: {
    backLink: 'uam.delegation.backLink',
  },
};

export const editPermissionsPage = {
  delegation: {
    delegate: {
      error: 'uam.delegation.delegate.error',
      amendPermissions: {
        button: {
          save: 'uam.delegation.delegate.amendPermissions.button.save',
          cancel: 'uam.delegation.delegate.amendPermissions.button.cancel',
        },
        failed: 'uam.delegation.delegate.amendPermissions.failed',
        permissionsDefined: {
          heading: 'uam.delegation.delegate.amendPermissions.permissionsDefined.heading',
          subHeading: 'uam.delegation.delegate.amendPermissions.permissionsDefined.subHeading',
        },
        permissionsUndefined: {
          heading: 'uam.delegation.delegate.amendPermissions.permissionsUndefined.heading',
          subHeading: 'uam.delegation.delegate.amendPermissions.permissionsUndefined.subHeading',
        },
      },
    },
  },
};

export const delegatePage = {
  delegation: {
    delegate: {
      error: 'uam.delegation.delegate.error',
      button: {
        changePermissions: 'uam.delegation.delegate.button.changePermissions',
        choosePermissions: 'uam.delegation.delegate.button.choosePermissions',
      },
    },
  },
};
