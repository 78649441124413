import { lazy } from 'react';
import HeaderBackLink from 'presentation/components/elements/HeaderBackLink';
import Message from '@ux/message';
import FormFocus from 'presentation/components/elements/FormFocus';
import IntroBlock from 'presentation/components/elements/IntroBlock';
import { usePageTitle } from 'domain/selectors/common';
import { routeConfig } from 'domain/constants/routes';
import { changePasswordScreen as uam } from 'presentation/locale/intl';

const ChangePasswordForm = lazy(() => import('presentation/components/modules/ChangePasswordForm'));

const pageId = 'uam.pageTitle.changePassword';

const ChangePassword = () => {
  const title = usePageTitle(pageId);

  return (
    <FormFocus
      title={title}
      pageId={pageId}
      backLink={
        <HeaderBackLink to={routeConfig.security}>
          <Message id={uam.security.password.backLink} />
        </HeaderBackLink>
      }
      introBlock={
        <IntroBlock
          heading={<Message id={uam.security.changePassword.title} />}
          body={<Message id={uam.security.changePassword.text} />}
        />
      }
    >
      <ChangePasswordForm />
    </FormFocus>
  );
};

ChangePassword.displayName = 'ChangePassword';
export default ChangePassword;
