import { ReactNode, Suspense, Children } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Column } from '@ux/grid';
import PageIntro from 'presentation/components/elements/PageIntro';
import Ribbon from '@ux/ribbon';
import PageLoader from 'presentation/components/screens/PageLoader';
import { useLocation } from 'react-router-dom';
import { useRoutingConfig } from 'domain/selectors/config';
import ExternalLinks from 'presentation/components/modules/ExternalLinks';

import { classNames } from './PageTemplate.stylesheet';
import WithPageNavigationMessage from '../WithPageNavigationMessage';

interface Props {
  readonly title: string;
  readonly pageIntroTitle: ReactNode;
  readonly pageIntroText: ReactNode;
  readonly children?: ReadonlyArray<ReactNode> | ReactNode;
  readonly preRibbon?: ReactNode;
  readonly useRibbon?: boolean;
  readonly columnConfig?: Record<string, number>;
  readonly pageId: string;
}

const PageTemplate = ({
  title,
  useRibbon = true,
  pageIntroTitle,
  pageIntroText,
  preRibbon,
  children,
  columnConfig = { xs: 12, lg: 4 },
  pageId,
}: Props) => {
  const { pathname } = useLocation();
  const { links: pages } = useRoutingConfig();
  const page = pages.find((p) => p.url.toLowerCase() === pathname.toLowerCase())?.name;
  const RibbonComponent = useRibbon ? Ribbon : 'div';

  return (
    <WithPageNavigationMessage pageId={pageId}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageIntro title={pageIntroTitle} text={pageIntroText} />
      {preRibbon}
      <RibbonComponent className={classNames.ribbon}>
        <Container>
          <Suspense fallback={<PageLoader />}>
            <div className={classNames.contentContainer}>
              <Row>
                {Children.map(children, (child) => (
                  <Column {...columnConfig}>
                    <div className={classNames.content}>{child}</div>
                  </Column>
                ))}
                {/* @ts-ignore: pageName is a key of UAM-config */}
                <ExternalLinks wrapperClass={classNames.content} columnConfig={columnConfig} pageName={page} />
              </Row>
            </div>
          </Suspense>
        </Container>
      </RibbonComponent>
    </WithPageNavigationMessage>
  );
};

PageTemplate.displayName = 'PageTemplate';

export default PageTemplate;
