import { Suspense, lazy } from 'react';
import PageLoader from 'presentation/components/screens/PageLoader/index';

const Subscriptions = lazy(() => import('presentation/components/screens/Subscriptions'));

const SubscriptionsPage = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Subscriptions />
    </Suspense>
  );
};

export default SubscriptionsPage;
