import { ComponentType } from 'react';
import { WubLoader } from '@ux/penders';
import { css } from 'linaria';
import { cssTheme } from '@uds/theme-utils';

interface Props {}

const root = css`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

const PageLoader: ComponentType<Props> = () => {
  return (
    <div className={root}>
      <WubLoader color={cssTheme.palette.primary} />
    </div>
  );
};

export default PageLoader;
