import { PaddingBox } from '@ux/padding-box';
import { BodyCopy01 } from '@ux/typography';
import Message from '@ux/message';
import { Container } from '@ux/grid';

import { useClassNames } from './SupportStrip.stylesheet';
import PhoneNumber from './PhoneNumber';

interface Props {
  readonly phoneNumber: string | number | null;
}

const SupportStrip = ({ phoneNumber }: Props) => {
  const classNames = useClassNames();
  if (phoneNumber == null) {
    return null;
  }
  return (
    <div className={classNames.container}>
      <Container>
        <PaddingBox>
          <div className={classNames.contentContainer}>
            <BodyCopy01 className={classNames.bodyCopy}>
              <Message
                id="uam.supportStrip.text"
                values={{
                  help: (
                    <strong>
                      <Message id="uam.supportStrip.help" />
                    </strong>
                  ),
                }}
              />
            </BodyCopy01>
            <PhoneNumber phoneNumber={phoneNumber.toString()} />
          </div>
        </PaddingBox>
      </Container>
    </div>
  );
};

SupportStrip.displayName = 'SupportStrip';
export default SupportStrip;
