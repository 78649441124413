import { Suspense, lazy } from 'react';
import PageLoader from 'presentation/components/screens/PageLoader';

const ConfirmEmail = lazy(() => import('presentation/components/screens/ConfirmEmail'));

const ConfirmEmailPage = () => (
  <Suspense fallback={<PageLoader />}>
    <ConfirmEmail />
  </Suspense>
);

export default ConfirmEmailPage;
