import { Suspense, lazy } from 'react';
import PageLoader from 'presentation/components/screens/PageLoader';
import { usePageTitle } from 'domain/selectors/common';
import WithPageNavigationMessage from 'presentation/components/elements/WithPageNavigationMessage';
import { Helmet } from 'react-helmet';

const pageId = 'uam.pageTitle.delegation';

const AccessOverview = lazy(() => import('presentation/components/screens/Delegation/AccessOverview'));

const AccessOverviewPage = () => {
  const title = usePageTitle(pageId);

  return (
    <Suspense fallback={<PageLoader />}>
      <WithPageNavigationMessage pageId={pageId}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <AccessOverview />
      </WithPageNavigationMessage>
    </Suspense>
  );
};

AccessOverviewPage.displayName = 'AccessOverviewPage';

export default AccessOverviewPage;
