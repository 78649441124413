import { ReactNode } from 'react';
import { Heading02, PreHeading01, SubHeading01, Typelockup } from '@ux/typography';
import { Container, Row, Column, Justify } from '@ux/grid';
import Ribbon from '@ux/ribbon';
import PaddingBox from '@ux/padding-box';
import { Colorway } from '@uds/theme-utils';

import { getClassNames } from './PageIntro.stylesheet';

interface Props {
  precursor?: ReactNode;
  title: ReactNode;
  text: ReactNode;
  cta?: ReactNode;
  colorway?: Colorway;
  isFullHeight?: boolean;
}

const PageIntro = ({ precursor, title, text, cta, colorway = Colorway.PRIMARY, isFullHeight = false }: Props) => {
  const classNames = getClassNames({ colorway, isFullHeight });

  return (
    <section className={classNames.root}>
      <Ribbon className={classNames.ribbon}>
        <Container>
          <Row justify={isFullHeight ? Justify.CENTER : Justify.FLEX_START}>
            <Column xs={12} lg={10}>
              <PaddingBox>
                <Typelockup
                  eyebrow={
                    <If condition={precursor != null}>
                      <PreHeading01 component="p">
                        <>{precursor}</>
                      </PreHeading01>
                    </If>
                  }
                  heading={
                    <Heading02 component={'h1'}>
                      <>{title}</>
                    </Heading02>
                  }
                  subheading={
                    <SubHeading01 component="p">
                      <>{text}</>
                    </SubHeading01>
                  }
                />
                <If condition={cta != null}>
                  <div className={classNames.cta}>{cta}</div>
                </If>
              </PaddingBox>
            </Column>
          </Row>
        </Container>
      </Ribbon>
    </section>
  );
};
export default PageIntro;
