import { css } from 'linaria';
import { cssTheme } from '@uds/theme-utils';

const root = css`
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: auto;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: ${cssTheme.typography.content};
`;

const mainStyles = css`
  flex: 1;
  background-color: ${cssTheme.palette.grey12};
  display: flex;
  flex-direction: column;
`;

const contentLayout = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const routerWrapper = css`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const classNames = {
  root,
  mainStyles,
  contentLayout,
  routerWrapper,
};
