import * as React from 'react';
import { Container, Row, Column, Align } from '@ux/grid';
import Message from '@ux/message';
import Select from '@ux/select';
import { useIntl } from 'react-intl';
import { PaddingBox } from '@ux/padding-box';
import { Heading03 } from '@ux/typography';

import { classNames } from './PreferredLanguage.stylesheet';

interface Props {
  readonly language: string;
  readonly supportedLanguages: ReadonlyArray<string>;
  readonly onChangeLanguage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const getLanguageKey = (language: string) => {
  switch (language) {
    case 'ru-ru':
      return 'country.language.iso2.ru.ru';
    case 'es-es':
      return 'country.language.iso2.es.es';
    default:
      return `country.language.iso2.${language.split('-').pop()}`;
  }
};

const PreferredLanguage = ({ language, supportedLanguages, onChangeLanguage }: Props) => {
  const { formatMessage } = useIntl();
  if (supportedLanguages.length < 2) {
    return null; // We don't want to show this when there is no other languages to select from
  }
  return (
    <section className={classNames.languageSection}>
      <Container>
        <Row align={Align.CENTER}>
          <Column xs={12} md={9}>
            <PaddingBox>
              <div className={classNames.contentContainer}>
                <Heading03 depth={2} className={classNames.heading}>
                  <Message id="uam.communication.language.title" />
                </Heading03>
                <Select onChange={onChangeLanguage} value={language}>
                  {supportedLanguages.map((lang) => (
                    <option key={lang} value={lang}>
                      {formatMessage({ id: getLanguageKey(lang) })}
                    </option>
                  ))}
                </Select>
              </div>
            </PaddingBox>
          </Column>
        </Row>
      </Container>
    </section>
  );
};

PreferredLanguage.displayName = 'PreferredLanguage';
export default PreferredLanguage;
