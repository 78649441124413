import { ReactNode, Suspense } from 'react';
import { Container, Row, Column } from '@ux/grid';
import Ribbon from '@ux/ribbon';
import { Helmet } from 'react-helmet';
import PageLoader from 'presentation/components/screens/PageLoader';

import { classNames } from './FormFocus.stylesheet';
import WithPageNavigationMessage from '../WithPageNavigationMessage';

interface Props {
  readonly introBlock: ReactNode;
  readonly children: ReactNode;
  readonly backLink: ReactNode;
  readonly title: string;
  readonly pageId: string;
}

const FormFocus = ({ introBlock, children, backLink, title, pageId }: Props) => (
  <WithPageNavigationMessage pageId={pageId}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {backLink}
    <div className={classNames.container}>
      <Ribbon>
        <Container>
          <Row>
            <Column xs={12} md={6} lg={4} offsetLg={2}>
              <div className={classNames.introBlockContainer}>{introBlock}</div>
            </Column>
            <Column xs={12} md={6} lg={4}>
              <Suspense fallback={<PageLoader />}>{children}</Suspense>
            </Column>
          </Row>
        </Container>
      </Ribbon>
    </div>
  </WithPageNavigationMessage>
);

FormFocus.displayName = 'FormFocus';
export default FormFocus;
