import App from 'presentation/root/ConnectedApp';
import { init as initApm } from '@elastic/apm-rum';

import di from './di';
import render from './render';

declare global {
  interface Window {
    ENVIRONMENT: string;
  }
}
const environment = window.ENVIRONMENT;

initApm({
  serviceName: 'uam-ui',
  environment,
  serverUrl: '/api/apm',
  serviceVersion: '1.0.0',
});

di();

render(App)();
