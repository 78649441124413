import { css, cx } from 'linaria';
import { cssTheme } from '@uds/theme-utils';
import { useHeadingS, useHeadingM, useHeadingL } from '@ux/typography';
import { breakpoints } from '@ux/responsive/constants';
import { useBreakpoints } from '@ux/responsive';

const container = css`
  border-top: 4px solid ${cssTheme.palette.primary};
  background-color: ${cssTheme.palette.highlightContrast};
`;

const bodyCopy = css`
  margin-top: 0 !important;
  margin-bottom: ${cssTheme.gu3} !important;

  @media (min-width: ${breakpoints.lg}px) {
    margin-bottom: 0 !important;
  }
`;

const phoneContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${cssTheme.palette.primary};
  font-weight: 500;
  font-family: ${cssTheme.typography.display};

  img {
    margin-right: ${cssTheme.gu2};
  }
`;

const contentContainer = css`
  @media (min-width: ${breakpoints.sm}px) {
    text-align: center;
  }
  @media (min-width: ${breakpoints.lg}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const useClassNames = () => {
  const { isMinSm, isMinLg } = useBreakpoints();
  const headingS = useHeadingS();
  const headingM = useHeadingM();
  const headingL = useHeadingL();
  return {
    container,
    bodyCopy,
    phoneContainer: cx(!isMinSm && headingS, isMinSm && !isMinLg && headingM, isMinLg && headingL, phoneContainer),
    contentContainer,
  };
};
