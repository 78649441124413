import { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import Message from '@ux/message';
import { routeConfig } from 'domain/constants/routes';
import { usePageTitle } from 'domain/selectors/common';
import PageLoader from 'presentation/components/screens/PageLoader';
import WithPageNavigationMessage from 'presentation/components/elements/WithPageNavigationMessage';
import HeaderBackLink from 'presentation/components/elements/HeaderBackLink';
import { pendingDelegateScreen as uam } from 'presentation/locale/intl';

const PendingDelegatePage = lazy(
  () => import('presentation/pages/delegation/PendingDelegatePage/ConnectedPendingDelegatePage'),
);

const pageId = uam.delegation.pendingDelegate.pageTitle;

const PendingDelegatePageWrapper = () => {
  const title = usePageTitle(pageId);

  return (
    <Suspense fallback={<PageLoader />}>
      <WithPageNavigationMessage pageId={pageId}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <HeaderBackLink to={routeConfig.delegation.root}>
          <Message id="uam.delegation.backLink" />
        </HeaderBackLink>
        <PendingDelegatePage />
      </WithPageNavigationMessage>
    </Suspense>
  );
};

PendingDelegatePageWrapper.displayName = 'PendingDelegatePageWrapper';

export default PendingDelegatePageWrapper;
