import React, { Suspense, ComponentType } from 'react';
import { matchPath } from 'react-router';
import { DropdownRouter } from 'react-dropdown-router';
import { ModalRouter } from 'react-modal-router';
import ReactiveKnowledge from '@uds/reactive-knowledge';
import { Footer, SalesHeader } from '@uds/bookends';
import ImpersonationRibbon from '@ux/impersonation-ribbon';
import { useRoutingConfig } from 'domain/selectors/config';
import { useLocation } from 'react-router-dom';
import SupportStrip from 'presentation/components/elements/SupportStrip';
import { routeConfig } from 'domain/constants/routes';
import { ReactRouterLink } from '@uds/react-router-adapter';

import Router from './Router';
import { classNames } from './Core.stylesheet';

interface OuterProps {}

const showBackLinkHeaderRoutes = [
  routeConfig.changePassword,
  routeConfig.changeEmail,
  routeConfig.editProfile,
  routeConfig.delegation.createInvitation,
  routeConfig.delegation.delegate,
  routeConfig.delegation.delegatePending,
  routeConfig.delegation.grantor,
];

const ConnectedCore: ComponentType<OuterProps> = () => {
  const { links } = useRoutingConfig();
  const location = useLocation();
  const showBackLink = matchPath(location.pathname, {
    path: showBackLinkHeaderRoutes,
    exact: true,
  });
  return <Core links={showBackLink == null ? links : []} />;
};

interface HeaderLink {
  readonly key: string;
  readonly label: string;
  readonly url: string;
}

interface InnerProps {
  links: HeaderLink[];
}

const Core = ({ links }: InnerProps) => (
  <ReactiveKnowledge>
    <ModalRouter>
      <div className={classNames.root}>
        <div className={classNames.contentLayout}>
          <ImpersonationRibbon />
          <Suspense fallback={null}>
            <DropdownRouter>
              <SalesHeader LinkComponent={ReactRouterLink} links={links} />
            </DropdownRouter>
          </Suspense>
          <main className={classNames.mainStyles}>
            <div className={classNames.routerWrapper}>
              <Router />
            </div>
            <SupportStrip />
          </main>
        </div>
        <Suspense fallback={null}>
          <Footer LinkComponent={ReactRouterLink} />
        </Suspense>
      </div>
    </ModalRouter>
  </ReactiveKnowledge>
);

export default ConnectedCore;
