import { lazy, Suspense, useEffect } from 'react';
import { Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import ConfirmEmail from 'presentation/pages/ConfirmEmailPage';
import ViewProfile from 'presentation/pages/ProfilePage/index';
import ViewCommunication from 'presentation/pages/CommunicationPage';
import SecurityPage from 'presentation/pages/SecurityPage';
import { useRoutingConfig } from 'domain/selectors/config';
import ChangePasswordPage from 'presentation/pages/ChangePasswordPage';
import ChangeEmailPage from 'presentation/pages/ChangeEmailPage';
import EditProfilePage from 'presentation/pages/EditProfilePage';
import PaymentMethodsPage from 'presentation/pages/PaymentMethodsPage';
import InvoicesPage from 'presentation/pages/InvoicesPage';
import SubscriptionsPage from 'presentation/pages/SubscriptionsPage';
import AccessOverviewPage from 'presentation/pages/delegation/AccessOverviewPage';
import CreateInvitationPage from 'presentation/pages/delegation/CreateInvitationPage';
import AcceptInvitePage from 'presentation/pages/delegation/AcceptInvitePage';
import AcceptInviteErrorPage from 'presentation/pages/delegation/AcceptInviteErrorPage';
import GrantorPage from 'presentation/pages/delegation/GrantorPage';
import DelegatePage from 'presentation/pages/delegation/DelegatePage';
import PendingDelegatePage from 'presentation/pages/delegation/PendingDelegatePage';
import EditPermissionsPage from 'presentation/pages/delegation/EditPermissionsPage';
import { ErrorBoundary } from 'react-error-boundary';
import PageLoader from 'presentation/components/screens/PageLoader';
import { routeConfig } from 'domain/constants/routes';
import { useWindow } from '@ux/hooks';
import { ApmRoute } from '@elastic/apm-rum-react';

const PageFailed = lazy(() => import('presentation/components/screens/PageFailed'));

const Router = () => {
  const { routes } = useRoutingConfig();
  const history = useHistory();
  const window = useWindow();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history, window]);

  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <Suspense fallback={<PageLoader />}>
          <PageFailed resetErrorBoundary={resetErrorBoundary} />
        </Suspense>
      )}
    >
      <Switch>
        <If condition={routes.includes('profile')}>
          <ApmRoute path={routeConfig.profile} exact={true} component={() => <ViewProfile />} />
          <ApmRoute exact={true} path={routeConfig.changeEmail} component={() => <ChangeEmailPage />} />
          <ApmRoute exact={true} path={routeConfig.editProfile} component={() => <EditProfilePage />} />
        </If>
        <If condition={routes.includes('communication')}>
          <ApmRoute exact={true} path={routeConfig.communication} component={() => <ViewCommunication />} />
        </If>
        <ApmRoute exact={true} path={routeConfig.emailConfirm} component={() => <ConfirmEmail />} />
        <If condition={routes.includes('security')}>
          <ApmRoute exact={true} path={routeConfig.security} component={() => <SecurityPage />} />
          <ApmRoute exact={true} path={routeConfig.changePassword} component={() => <ChangePasswordPage />} />
        </If>
        <If condition={routes.includes('paymentMethods')}>
          <ApmRoute exact={true} path={routeConfig.paymentMethods} component={() => <PaymentMethodsPage />} />
        </If>
        <If condition={routes.includes('invoices')}>
          <ApmRoute exact={true} path={routeConfig.invoices} component={() => <InvoicesPage />} />
        </If>
        <If condition={routes.includes('subscriptions')}>
          <ApmRoute exact={true} path={routeConfig.subscriptions} component={() => <SubscriptionsPage />} />
        </If>
        <If condition={routes.includes('delegation')}>
          <ApmRoute exact={true} path={routeConfig.delegation.root} component={() => <AccessOverviewPage />} />
          <ApmRoute
            exact={true}
            path={routeConfig.delegation.createInvitation}
            component={() => <CreateInvitationPage />}
          />
          <ApmRoute
            exact={true}
            path={routeConfig.delegation.invitationError}
            component={() => <AcceptInviteErrorPage />}
          />
          <ApmRoute path={routeConfig.delegation.invitation} component={() => <AcceptInvitePage />} />
          <ApmRoute path={routeConfig.delegation.grantor} component={() => <GrantorPage />} />
          <ApmRoute path={routeConfig.delegation.delegatePending} component={() => <PendingDelegatePage />} />
          <ApmRoute path={routeConfig.delegation.permissions} component={() => <EditPermissionsPage />} />
          <ApmRoute path={routeConfig.delegation.delegate} component={() => <DelegatePage />} />
        </If>
      </Switch>
    </ErrorBoundary>
  );
};

export default Router;
