import { ReactNode } from 'react';
import { css } from 'linaria';

interface Props {
  readonly 'children': ReactNode;
  readonly 'role'?: string;
  readonly 'aria-live'?: 'off' | 'assertive' | 'polite';
  readonly 'aria-atomic'?: boolean | 'true' | 'false';
}

/* 
This utility class shrinks an element into a 1px square, hiding any overflow, 
and absolutely positioning the element to remove any trace of it from the normal document flow. 
This utility class is ideal for providing screen reader-only text.
https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
*/
const srOnly = css`
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const InclusivelyHidden = ({ children, ...rest }: Props) => {
  return (
    <span className={srOnly} {...rest}>
      {children}
    </span>
  );
};

InclusivelyHidden.displayName = 'InclusivelyHidden';
export default InclusivelyHidden;
