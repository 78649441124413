import { useState } from 'react';
import * as React from 'react';
import { useLanguage, useSaveLanguage } from '@ux/language';
import { useBrandConfig } from '@ux/whitelabel';

import PreferredLanguage from './PreferredLanguage';

const ConnectedPreferredLanguage = () => {
  const { data: currentLanguage } = useLanguage({ suspense: true });
  const { languages } = useBrandConfig();
  const [language, setLanguage] = useState(currentLanguage ?? languages[0]);
  const { mutate } = useSaveLanguage();
  const onChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value);
    mutate({ language: event.target.value });
  };

  return <PreferredLanguage onChangeLanguage={onChangeLanguage} supportedLanguages={languages} language={language} />;
};

ConnectedPreferredLanguage.displayName = 'ConnectedPreferredLanguage';
export default ConnectedPreferredLanguage;
