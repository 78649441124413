import { css } from 'linaria';
import { cssTheme } from '@uds/theme-utils';
import { breakpoints } from '@ux/responsive/constants';

const backLinkWrapper = css`
  background-color: ${cssTheme.palette.primary};
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: ${cssTheme.gu1} ${cssTheme.gu1};
  /* We remove the links from the bookends link-config, and it leaves a 1px gap, we are adding margin-top -1px here to close this gap */
  margin-top: -1px;

  @media (min-width: ${breakpoints.lg}px) {
    padding: ${cssTheme.gu2} ${cssTheme.gu1};
  }
`;

const button = css`
  width: unset !important;
  background-color: transparent !important;
  &:focus {
    text-decoration: underline !important;
    outline: none !important;
    border-width: 0 !important;
    box-shadow: none !important;
    outline-offset: 0 !important;
  }
  & > span:first-of-type {
    margin-top: 2px;
  }
`;

export const classNames = {
  backLinkWrapper,
  button,
};
