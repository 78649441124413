import { lazy } from 'react';
import PreferredLanguage from 'presentation/components/modules/PreferredLanguage';
import PageTemplate from 'presentation/components/elements/PageTemplate';
import Message from '@ux/message';
import { usePageTitle } from 'domain/selectors/common';
import cookies from 'browser-cookies';

//copied from utag.js to keep the same solution to setting the cookie
//it might look weird, i just wanted to keep the exact same functionality
//this will take the domain + tld even if we have .co.uk
//account.123-reg.co.uk -> .123-reg.co.uk
//ote.123-reg.co.uk -> .123-reg.co.uk
const tealiumGetDomain = () => {
  try {
    const hostname = `${window.location.hostname}`;
    const hostnameParts = hostname.split('.');
    const pathSplit = /\.co\.|\.com\.|\.org\.|\.edu\.|\.net\.|\.asn\./.test(hostname) ? 3 : 2;
    const domain = hostnameParts.splice(hostnameParts.length - pathSplit, pathSplit).join('.');
    return domain;
  } catch (e) {
    return window.location.hostname;
  }
};

const Preferences = lazy(() => import('presentation/components/modules/CommunicationPreferences'));

const pageId = 'uam.pageTitle.communication';

const Communication = () => {
  const title = usePageTitle(pageId);
  //its a hack but necessary until we remove tealium as a dependency
  //useTealium in privacy manager seems to only be used for the optout cookie
  //and a utag view to get the new data, which again, not necessary here
  //we need to remove that

  const modifiedWindow = window as { [key: string]: any };
  modifiedWindow['utag'] = modifiedWindow['utag'] ?? {
    //for setting the cookie when tealium is not present
    cfg: {
      domain: tealiumGetDomain(),
    },
    data: {
      'cp.OPTOUTMULTI': cookies.get('OPTOUTMULTI'),
    },
    view: () => {},
  };
  return (
    <>
      <PageTemplate
        pageId={pageId}
        columnConfig={{ xs: 12 }}
        title={title}
        pageIntroTitle={<Message id="uam.communication.page.title" />}
        pageIntroText={<Message id="uam.communication.page.text" />}
        preRibbon={<PreferredLanguage />}
        useRibbon={false}
      >
        {null}
      </PageTemplate>
      {/* Note this needs to be rendered outside of PageTemplate since it contains its own container */}
      <Preferences />
    </>
  );
};

Communication.displayName = 'Communication';
export default Communication;
