import { Suspense, cloneElement, ReactElement } from 'react';
import { IdpProvider, useAuthGuard } from '@ux/auth';
import { useMessages, useLanguage } from '@ux/language';
import PageLoader from 'presentation/components/screens/PageLoader/PageLoader';
import { History } from 'history';
import { OrchestrationProvider } from '@ux/orchestration';
import { useTheme, useAssets } from '@ux/whitelabel';
import { Helmet } from 'react-helmet';
import { BreakpointRoot } from '@ux/responsive';
import { QueryClientProvider, QueryClient } from 'react-query';
import TypographyImporter from '@ux/typography-importer';
import { BrowserRouter } from 'react-router-dom';
import { buildCssVars, ThemeProvider } from '@uds/theme-utils';
import { apmBase } from '@elastic/apm-rum';

import App, { OuterProps } from './App';

const apps = ['brand', 'uam', 'bks'];
const scopes = ['brand', 'uam', 'bks', 'ux', 'country', 'cookie'];

const AuthGuard = ({ children }: { children: ReactElement }) => {
  useAuthGuard();
  const { data: messages } = useMessages();

  const { data: locale } = useLanguage();
  const { favicon } = useAssets() ?? {};

  return <>{cloneElement(children, { messages, locale: locale ?? 'en', favicon })}</>;
};

interface Props extends OuterProps {
  readonly history: History;
}

const queryCache = new QueryClient({
  defaultOptions: {
    mutations: {
      onError(error: Error, variables, context) {
        apmBase.captureError(error ?? `Undefined error happened during a mutation: ${variables}, ${context}`);
      },
    },
    queries: {
      onError(err: Error) {
        apmBase.captureError(err ?? 'Undefined error happened during fetch');
      },
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: (count: number, err: { statusCode?: string | number; status?: number | string }) => {
        if (count >= 3 || `${err?.statusCode}`.startsWith('4') || `${err?.status}`.startsWith('4')) {
          return false;
        }
        return true;
      },
    },
  },
});

const ReactQueryProvider = (props: Props) => {
  return (
    <BrowserRouter>
      <IdpProvider>
        <QueryClientProvider client={queryCache} contextSharing={true}>
          <OrchestrationProvider loader={<PageLoader />} scopes={scopes} apps={apps}>
            <ConnectedApp {...props} />
          </OrchestrationProvider>
        </QueryClientProvider>
      </IdpProvider>
    </BrowserRouter>
  );
};

const ConnectedApp = ({ history, ...rest }: Props) => {
  const theme = useTheme();
  // @ts-ignore: Let's fix it later
  const themeVars = buildCssVars(theme);
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <style>{themeVars}</style>
        <meta name="theme-color" content={theme.palette.primary} />
      </Helmet>
      <BreakpointRoot>
        {/* @ts-ignore */}
        <TypographyImporter theme={theme} />
        <Suspense fallback={<PageLoader />}>
          <AuthGuard>
            <App {...rest} />
          </AuthGuard>
        </Suspense>
      </BreakpointRoot>
    </ThemeProvider>
  );
};

export default ReactQueryProvider;
