import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';

const useQueryParams = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  return searchParams;
};

export const useQueryParam = (path: string) => {
  return useQueryParams().get(path);
};

export const usePageTitle = (pageId: string) => {
  const intl = useIntl();
  const page = intl.formatMessage({ id: pageId });
  const brand = intl.formatMessage({ id: 'brand.pageTitles.fixture' });

  const title = intl.formatMessage(
    {
      id: 'uam.pageTitle.template.structure.primary',
    },
    {
      page,
      brand,
    },
  );
  return title;
};
