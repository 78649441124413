import { ReactNode } from 'react';
import Message from '@ux/message';

import InclusivelyHidden from '../InclusivelyHidden';

interface Props {
  readonly pageId: string;
  readonly children: ReactNode;
}

/* 
  Since screen readers don't understand navigation in SPA's,
  we need this component to announce that navigation has happened.
*/
const WithPageNavigationMessage = ({ pageId, children }: Props) => {
  return (
    <>
      {children}
      <InclusivelyHidden aria-atomic="true" aria-live="polite" role="status">
        <Message id="uam.navigation.message" values={{ pageName: <Message id={pageId} /> }} />
      </InclusivelyHidden>
    </>
  );
};

WithPageNavigationMessage.displayName = 'WithPageNavigationMessage';
export default WithPageNavigationMessage;
