import { Colorway, cssTheme } from '@uds/theme-utils';
import { css, cx } from 'linaria';

interface Props {
  readonly colorway: Colorway;
  readonly isFullHeight: boolean;
}

const root = css`
  background-color: ${cssTheme.palette.primary};
  color: ${cssTheme.palette.primaryContrast};
`;

const rootNeutral = css`
  background-color: ${cssTheme.palette.grey12};
`;

const colorNeutral = css`
  color: ${cssTheme.palette.grey04};
`;

const cta = css`
  margin-top: ${cssTheme.gu3};
`;

const fullHeight = css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-align: center;
`;

const ribbon = css`
  width: 100%;
`;

export const getClassNames = ({ colorway, isFullHeight }: Props) => {
  return {
    root: cx(root, colorway === Colorway.NEUTRAL && cx(rootNeutral, colorNeutral), isFullHeight && fullHeight),
    title: cx(colorway === Colorway.NEUTRAL && colorNeutral),
    cta,
    ribbon,
  };
};
