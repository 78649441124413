import { css } from 'linaria';
import { cssTheme } from '@uds/theme-utils';
import { breakpoints } from '@ux/responsive/constants';

const languageSection = css`
  background-color: #fff;
`;

const heading = css`
  margin-bottom: ${cssTheme.gu3} !important;

  @media (min-width: ${breakpoints.sm}px) {
    margin-bottom: 0 !important;
    margin-right: ${cssTheme.gu3} !important;
  }
`;

const contentContainer = css`
  @media (min-width: ${breakpoints.sm}px) {
    > * {
      display: inline-block !important;
    }
    > div {
      width: unset !important;
    }
  }
`;

export const classNames = {
  languageSection,
  heading,
  contentContainer,
};
