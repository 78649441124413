import parsePhoneNumber from 'libphonenumber-js/max';

export const getDifferenceInHoursAndMinutes = (date1: Date, date2: Date) => {
  const differenceInMinutes = (date1.getTime() - date2.getTime()) / 1000 / 60;
  return {
    hours: Math.floor(differenceInMinutes / 60),
    minutes: Math.floor(differenceInMinutes % 60),
  };
};

export const formatPhoneNumber = (number: string | number) => {
  try {
    const parsed = parsePhoneNumber(number.toString());
    return parsed?.formatInternational() ?? number.toString();
  } catch {
    return number;
  }
};
