import PageTemplate from 'presentation/components/elements/PageTemplate';
import Message from '@ux/message';
import { usePageTitle } from 'domain/selectors/common';

const pageId = 'uam.pageTitle.paymentMethods';

const PaymentMethods = () => {
  const title = usePageTitle(pageId);

  return (
    <PageTemplate
      pageId={pageId}
      pageIntroTitle={<Message id="uam.paymentMethods.page.title" />}
      pageIntroText={<Message id="uam.paymentMethods.page.text" />}
      title={title}
    />
  );
};

PaymentMethods.displayName = 'PaymentMethods';
export default PaymentMethods;
