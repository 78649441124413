import { Suspense, lazy } from 'react';
import PageLoader from 'presentation/components/screens/PageLoader';

const AcceptInvite = lazy(() => import('presentation/components/screens/Delegation/AcceptInvite'));

const AcceptInvitePage = () => (
  <Suspense fallback={<PageLoader />}>
    <AcceptInvite />
  </Suspense>
);

AcceptInvitePage.displayName = 'AcceptInvitePage';

export default AcceptInvitePage;
