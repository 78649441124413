import { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import Message from '@ux/message';
import { routeConfig } from 'domain/constants/routes';
import { usePageTitle } from 'domain/selectors/common';
import PageLoader from 'presentation/components/screens/PageLoader';
import WithPageNavigationMessage from 'presentation/components/elements/WithPageNavigationMessage';
import HeaderBackLink from 'presentation/components/elements/HeaderBackLink';
import { grantorScreen as uam } from 'presentation/locale/intl';

const GrantorPage = lazy(() => import('presentation/pages/delegation/GrantorPage/ConnectedGrantorPage'));

const pageId = uam.delegation.grantor.pageTitle;

const GrantorPageWrapper = () => {
  const title = usePageTitle(pageId);

  return (
    <Suspense fallback={<PageLoader />}>
      <WithPageNavigationMessage pageId={pageId}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <HeaderBackLink to={routeConfig.delegation.root}>
          <Message id="uam.delegation.backLink" />
        </HeaderBackLink>
        <GrantorPage />
      </WithPageNavigationMessage>
    </Suspense>
  );
};

GrantorPageWrapper.displayName = 'GrantorPageWrapper';

export default GrantorPageWrapper;
