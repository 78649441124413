import { Helmet } from 'react-helmet';
import { HeadingRoot, HeadingSection } from '@team-griffin/react-heading-section';
import { IntlProvider } from 'react-intl';
import { Theme } from '@uds/theme-utils';
/**
 * By default, React Query Devtools are not included in production bundles when process.env.NODE_ENV === 'production',
 * so you don't need to worry about excluding them during a production build.
 * https://react-query.tanstack.com/devtools#import-the-devtools
 */
import { ReactQueryDevtools } from 'react-query/devtools';

import Core from './Core';

export interface OuterProps {
  theme: Theme;
  favicon: string;
  locale: string;
  messages?: Record<string, string>;
}

const App = ({ messages, locale, favicon, ...props }: OuterProps) => (
  <>
    <Helmet htmlAttributes={{ lang: locale.split('-').shift() }}>
      <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
    </Helmet>
    <IntlProvider locale={locale} messages={messages}>
      <HeadingRoot>
        <HeadingSection>
          <Core {...props} />
        </HeadingSection>
      </HeadingRoot>
    </IntlProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </>
);

export default App;
