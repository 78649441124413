import { Suspense, lazy } from 'react';
import PageLoader from 'presentation/components/screens/PageLoader';

const AcceptInviteError = lazy(() => import('presentation/components/screens/Delegation/AcceptInviteError'));

const AcceptInviteErrorPage = () => (
  <Suspense fallback={<PageLoader />}>
    <AcceptInviteError />
  </Suspense>
);

AcceptInviteErrorPage.displayName = 'AcceptInviteErrorPage';

export default AcceptInviteErrorPage;
