import { cssTheme } from '@uds/theme-utils';
import { css } from 'linaria';
import { breakpoints } from '@ux/responsive/constants';

export const classNames = {
  content: css`
    margin-bottom: ${cssTheme.gu1};

    @media (min-width: ${breakpoints.sm}px) {
      margin-bottom: ${cssTheme.gu2};
    }
  `,
  // This will cancel out the margin of the bottom most component
  contentContainer: css`
    margin-bottom: calc(${cssTheme.gu1} * -1);

    @media (min-width: ${breakpoints.sm}px) {
      margin-bottom: calc(${cssTheme.gu2} * -1);
    }
  `,
  ribbon: css`
    flex: 1;
  `,
};
